import type { I18n } from "@lingui/core";
import { en, nl, tr } from "make-plural/plurals";
import { useRouter } from "next/router";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";

//anounce which locales we are going to use and connect them to approprite plural rules
export function initTranslation(i18n: I18n): void {
  i18n.loadLocaleData({
    en: { plurals: en },
    nl: { plurals: nl },
    "nl-be": { plurals: nl },
    tr: { plurals: tr },
    "tr-tr": { plurals: tr },
    pseudo: { plurals: en },
  });
}

export async function loadTranslation(locale: string, isProduction = true) {
  let data;
  if (isProduction) {
    data = await import(`./translations/locales/${locale}/messages`);
  } else {
    data = await import(
      `@lingui/loader!./translations/locales/${locale}/messages.po`
    );
  }

  return data.messages;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(
  path: string,
  urlParamsObject = {},
  options = {}
) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL +
    `/api${path}${queryString ? `?${queryString}` : ""}`
  }`;

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    console.error(response.statusText);
  }
  const data = await response.json();
  return data;
}

export async function fetchQuery(
  path: string,
  urlParamsObject = {},
  options = {}
) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject, { encodeValuesOnly: true });

  const requestUrl = `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL +
    `/api${path}${queryString ? `?${queryString}` : ""}`
  }`;

  // Trigger API call
  return fetch(requestUrl, mergedOptions).then((res) => res.json());
}

export function getStrapiMedia(media: any) {
  if (media.data) {
    const { url } = media.data.attributes;
    // const imageUrl = url;
    const imageUrl = url.startsWith("/")
      ? process.env.NEXT_PUBLIC_STRAPI_API_URL + url
      : url;
    return imageUrl;
  }
  https: return "";
}

//www.digifist.com/_next/image?url=https%3A%2F%2Fcmsad.digifist.com%2Fuploads%2FFrame_991893_min_cfeaa1f10f.jpg&w=3840&q=75

export function getStrapiMediaArr(media: any) {
  const { url } = media.attributes;
  const imageUrl = url.startsWith("/")
    ? process.env.NEXT_PUBLIC_STRAPI_API_URL + url
    : url;
  return imageUrl;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const payloadPopulate = {
  Sections: {
    populate: {
      Image2: "*",
      Link: {
        populate: "*",
      },
      Link1: {
        populate: "*",
      },
      ImageTabs: {
        populate: "*",
      },
      StackPartners: {
        populate: "*",
      },
      PromiseBoxes: {
        populate: "*",
      },
      Link2: {
        populate: "*",
      },
      Box: {
        populate: "*",
      },
      SizeBox: {
        populate: "*",
      },
      Text: "*",
      Logos: {
        populate: "*",
      },
      LogosImages: "*",
      Tab: "*",
      Project: {
        populate: {
          project: {
            populate: "*",
          },
        },
      },
      slider: {
        populate: {
          Slide: {
            populate: {
              Title: "*",
              Subtitle: "*",
              Badge: "*",
              Image: "*",
              project: {
                populate: {
                  Badge: "*",
                  Image: "*",
                },
              },
              article: {
                populate: "*",
              },
            },
          },
        },
      },
      Slide: {
        populate: {
          Image: "*",
        },
      },
      Testimonials: "*",
      Image: {
        populate: "*",
      },
      VideoPlaceholder: {
        populate: "*",
      },
      Accordion: {
        populate: "*",
      },
      Members: {
        populate: "*",
      },
      Button: {
        populate: "*",
      },
      Columns: {
        populate: "*",
      },
      Images: {
        populate: "*",
      },
      FormBuilder: {
        populate: "*",
      },
      FormField: {
        populate: "*",
      },
      ZapierHooks: {
        populate: "*",
      },
    },
  },
  localizations: "*",
  SEO: {
    populate: "*",
  },
};

export const fetchPayloadPage = (ctx: any) => ({
  populate: payloadPopulate,
  locale: ctx.locale,
  filters: {
    slug: ctx.params?.slug,
  },
});

export const getStaticPropsPage = async (ctx: any, url: string) => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === "production"
  );
  const locale = getLocale(ctx.locale);

  const { data } = await fetchAPI(url, {
    populate: payloadPopulate,
    locale: locale,
    filters: {
      $or: [
        {
          slug: ctx.params?.slug,
        },
        {
          slug_overwrite: ctx.params?.slug,
        },
      ],
    },
  });

  return {
    props: {
      translation,
      data: data.pop(),
    },
    revalidate: 10,
  };
};

export const articleDate = (date: Date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) setTargetReached(true);
    else setTargetReached(false);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener("change", updateTarget);
    if (media.matches) setTargetReached(true);

    return () => media.removeEventListener("change", updateTarget);
  }, []);

  return targetReached;
};

export const getLocale = (local: any) => {
  const locales: any = {
    "nl-be": "nl-BE",
    en: "en",
    "tr-tr": "tr-TR",
    pseudo: "pseudo",
  };

  return locales[local] ? locales[local] : "en";
};

export const scrollToSection = (element: any) => {
  const { top } = element.getBoundingClientRect();
  const header = document.querySelector(".header");
  if (header && top) {
    window.scrollTo({
      top: window.scrollY + top - header.clientHeight,
      behavior: "smooth",
    });
  }
};

export const scrollToSectionAccordion = (element: any) => {
  const { top } = element.getBoundingClientRect();
  const header = document.querySelector(".header");
  let headerHeight = 0;
  if (header) {
    headerHeight = header.clientHeight;
  }
  const scrollTo = window.scrollY + top - headerHeight;
  // console.log("scrollTo", scrollTo);
  if (header && top) {
    window.scrollTo({
      top: scrollTo,
      behavior: "smooth",
    });
  }
};

export const handleBaseLocales = (locales: any) =>
  locales
    .filter((item: string) => item !== "default")
    .map((item: string) => ({
      locale: item,
    }));

export const getSlug = (data: any) =>
  data.slug_overwrite || data.Slug || data.slug;

export const getLocalizationData = (
  data: any,
  pageSlug: string = "",
  relation: string = ""
) => {
  if (!data) {
    return null;
  }

  const localizationData: any = {};
  const otherAtts = data.attributes?.localizations?.data[0]?.attributes || null;

  localizationData["currentLangCode"] = data.attributes?.locale || null;
  localizationData["currentSlug"] =
    pageSlug || data.attributes?.slug_overwrite || data.attributes?.slug;

  if (otherAtts) {
    let relationSlug = "";
    let otherPageSlug = pageSlug || otherAtts.slug_overwrite || otherAtts.slug;

    if (relation) {
      const otherAppAtts = otherAtts[relation]?.data[0]?.attributes;
      relationSlug = (otherAppAtts.slug_overwrite || otherAppAtts.slug) + "/";
    }

    localizationData["anotherLocale"] = {
      [otherAtts.locale]: relationSlug + otherPageSlug,
    };
  }

  return localizationData;
};

export const useThemeString = () => {
  const router = useRouter();
  const themeString = router.locale === "nl-be" ? "shopify-themas" : "themes";
  return themeString;
};

export function capitalize(s: string) {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
}
